@import url('https://cdn.syncfusion.com/ej2/material.css');

.sidebar {
    box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}

.nav-item,
.navbar {
    z-index: 10000;
}

@media screen and (max-width:800px) {
    .sidebar {
        z-index: 10000000;
    }
}

.e-dlg-center-center,
.e-quick-popup-wrapper.e-device {
    z-index: 1000000 !important;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(216, 216, 216);
    border-radius: 40px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

/* color-picker style  */

#preview {
    background: transparent url('https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png') no-repeat;
    display: inline-block;
    height: 80px;
    margin: 10px 0;
    min-width: 300px;
    max-width: 600px;
    background-color: #008000;
}

.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
    border: none;
}

.darkbg {
    background: #dddddd;
}

.semibg {
    background: #ededed;
}

.lightbg {
    background: #e8e8e8;
}

.green {
    background: #16A085;
}

.white {
    background: #f9f9f9;
}

.darkblue {
    background: #2C3E54;
}

.bordergreen {
    border: 1px solid #7F8C8D;
}

.borderred {
    border: 1px solid #A41B1B;
}

.innerred {
    background: #FFE1E1;
}

.blue {
    background: #224078;
}

.violet {
    background: #6A34B5;
}


.vcolor {
    color: #6A34B5;
}


.gcolor {
    color: #16A085 !important;
}

.buybg {
    background: #DEE8EB;
}

.e-currentitem {
    background: #16A085 !important;
    border-radius: 1px !important;
}



.e-gridpager {
    display: flex;
    justify-content: space-between;
    background: #e8e8e8;
}

.e-pagercontainer {
    display: flex !important;
    order: 2 !important;
    padding: 24px 0;
    justify-content: right;
    background: #e8e8e8 !important;
    align-items: center !important;

}

.e-pagercontainer div.e-icons {
    background: white;
    margin-right: 1px !important;
    padding: 10px 5px !important;
}

.e-icons {
    display: inline-flex !important;
    margin: 0 !important;
    padding: 12px 5px !important;
    align-items: center !important;
    align-content: center !important;
    height: 38px;


}

.e-numericitem {
    padding: 10px 5px !important;
    border-radius: 1px !important;
    margin-right: 1px !important;
    color: #16A085 !important;
    font-size: 18px;

}

.e-pagercontainer .e-active {
    color: white !important;


}

.e-parentmsgbar {
    margin-left: 25px;
    order: 1 !important;
    visibility: hidden !important;
}

span.e-headertext {
    font-weight: bold !important;
    font-size: 16px;
}

table {
    font-family: 'Roboto'sans-serif;
}

table.tr {
    font-size: 1px !important;
}

.e-rowcell {
    font-size: 12px !important;
    font-weight: bold !important;
}


#gridcomp>div.e-gridpager.e-control.e-pager.e-lib>div.e-mnext.e-icons.e-icon-next.e-nextpage.e-pager-default,
#gridcomp>div.e-gridpager.e-control.e-pager.e-lib>div.e-mlast.e-icons.e-icon-last.e-lastpage.e-pager-default,
#gridcomp>div.e-gridpager.e-control.e-pager.e-lib>div.e-mfirst.e-icons.e-icon-first.e-firstpagedisabled.e-disable,
#gridcomp>div.e-gridpager.e-control.e-pager.e-lib>div.e-mprev.e-icons.e-icon-prev.e-prevpagedisabled.e-disable {
    display: none !important;
}


/* Login styling */

.loginbackground {
    background: #efefef;
}

.textcolor {
    color: #f7f8f9;
}

.supportbg {
    background: #f7f8f9;
}

.border {
    border: 1px solid #f7f8f9;
}

.greentext {
    color: #16A085;
}

.logindeco {
    border: 1px solid #bdc3c7;
    color: #1d1d1d;
    outline: none;
}

.darktextgreen {
    color: #1c7777;
}

.darkgreen {
    background: #1c7777;
}

.darkredborder {
    border: 1px solid #a41b1b;
}

.darkerror {
    background: #f3b6b6;
}

#gridcomp>div.e-gridheader.e-lib.e-droppable>div>table>thead>tr .e-headercelldiv {
    justify-items: center;
    margin: 10px 0 -10px 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

/* .bg-black{
    background-color: #000 !important;
    display: flex !important;
    justify-content: center;
    justify-items: center;
    width: 100vw;
    height: 100vh;
} */
.e-grid {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.e-grid .e-rowcell .e-removewrap {
    white-space: nowrap !important;
}

#gridcomp {
    overflow-x: scroll !important;

}

.e-pagercontainer {
    
    width: 100%;
}

.e-numericcontainer{
    display: flex;
    flex-direction: row !important;
}

@media screen and (max-width:480px) {
    .e-pagercontainer {
        width: 100%;
    }
    
}
@media screen and (min-width:480px) {
    .e-pagercontainer {
        width: 100%;
    }
    
}

.e-parentmsgbar{
    display: none !important;
}

#gridcomp > div.e-gridpager.e-control.e-pager.e-lib > div.e-mfirst.e-icons.e-icon-first.e-firstpage.e-pager-default,
#gridcomp > div.e-gridpager.e-control.e-pager.e-lib > div.e-mprev.e-icons.e-icon-prev.e-prevpage.e-pager-default,
#gridcomp > div.e-gridpager.e-control.e-pager.e-lib > div.e-mnext.e-icons.e-icon-next.e-nextpagedisabled.e-disable,
#gridcomp > div.e-gridpager.e-control.e-pager.e-lib > div.e-mlast.e-icons.e-icon-last.e-lastpagedisabled.e-disable{
 display:none !important;   
}